<template>
  <div class="">
    <div class="d-none">
      <div class="ptv" ref="documente">
        <div class="row">
          <div class="col-12 row mb-3">
            <div class="col-6 d-flex" :set="(avatar = getAvatar())">
              <img
                v-if="avatar"
                :src="avatar"
                alt=""
                width="70"
                height="70"
                class="mr-2"
              />
              <div v-if="clinic">
                <p class="mb-0 fz-10">
                  <span class="font-weight-bold tnr">{{ clinic.name }}</span>
                </p>
                <p class="mb-0 fz-10 tnr">Địa chỉ: {{ clinic.address }}</p>
                <p class="mb-0 fz-10 tnr">Số điện thoại: {{ clinic.phone }}</p>
                <p class="mb-0 fz-10 tnr">Email: {{ clinic.email }}</p>
              </div>
            </div>
            <div class="col-6">
              <p class="mb-0 text-center text-uppercase font-weight-bold fz-11">
                CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
              </p>
              <p class="mb-0 text-center font-weight-bold fz-11">
                Độc lập - Tự do - Hạnh phúc
              </p>
              <p class="mb-3 text-center font-weight-bold fz-11">
                ------------------------------------
              </p>
            </div>
          </div>
          <div class="col-12">
            <p class="mb-3 text-center text-uppercase font-weight-bold fz-16">
              Phiếu Khám bệnh
            </p>
          </div>
          <div class="col-12">
            <p class="mb-3 text-right fz-11 font-italic">
              Thời gian bắt đầu:
              {{ getDateTime3(form && form.start_time) || "..." }}
            </p>
            <div class="row mb-0">
              <div class="col-6">
                <p class="mb-2 fz-13">
                  Họ và tên:
                  <span class="font-weight-bold text-uppercase tnr">{{
                    !user
                      ? (form && form.patient_name) || "..."
                      : (user && user.name) || "..."
                  }}</span>
                </p>
              </div>
              <div class="col-6">
                <p class="mb-2 fz-13">
                  Số hồ sơ:
                  <span class="text-uppercase tnr">{{
                    (form && form.profile_number) || "..."
                  }}</span>
                </p>
              </div>
            </div>
            <p class="mb-2 fz-13">
              Giới tính:
              {{
                !user
                  ? (form && form.gender === 1 ? "Nam" : "Nữ") ||
                    "..............."
                  : (user && user.gender === 1 ? "Nam" : "Nữ") ||
                    "..............."
              }}, Ngày sinh:
              {{
                !user
                  ? (form && form.birthday) || ".../.../......"
                  : (user && user.birthday) || ".../.../......"
              }}, Nghề nghiệp:
              {{
                !user
                  ? (form && form.career) || "..........."
                  : (user && user.career) || "..........."
              }}
            </p>
            <p class="mb-2 fz-13">
              Địa chỉ:
              {{
                !user
                  ? (form && form.address) ||
                    "................................................................"
                  : (user && user.address) ||
                    "................................................................"
              }}
            </p>
            <p class="mb-2 fz-13">
              Số điện thoại:
              {{
                !user
                  ? (form && form.phone) || ".................."
                  : (user && user.phone) || ".................."
              }}
            </p>
            <p class="mb-3 fz-13">
              Người liên hệ:
              <span class="font-weight-bold text-uppercase tnr">{{
                (form && form.emergency_contact) || "....................."
              }}</span
              >, Số điện thoại:
              {{ (form && form.contact_phone) || "..............." }}
            </p>
            <p class="mb-2 font-weight-bold">Lý do khám bệnh:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.reason }}</p>
            <div v-if="!form || !form.reason" class="wordbreak mb-2 ml-4">
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Diễn biến:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.progression }}</p>
            <div v-if="!form || !form.progression" class="wordbreak mb-2 ml-4">
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Kết quả xét nghiệm:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.prehistoric }}</p>
            <div v-if="!form || !form.prehistoric" class="wordbreak mb-2 ml-4">
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Chẩn đoán:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.diagnose }}</p>
            <div v-if="!form || !form.diagnose" class="wordbreak mb-2 ml-4">
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Chỉ định:</p>
            <p class="mb-2 pre-wrap ml-4" v-if="form && form.indication">
              {{ form && form.indication }}
            </p>
            <div v-if="!form || !form.indication" class="wordbreak mb-2 ml-4">
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="text-center">
              <p class="mb-2 fz-13">
                {{ getHMDMYVN(form && form.end_time) || ".........." }}
              </p>
              <p class="mb-5 fz-13">Bác Sĩ Khám Bệnh</p>
              <p class="mb-5 fz-13">
                {{ doctor && doctor.doctor && doctor.doctor.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-col items-end" v-if="form">
      <div class="flex flex-col w-72 py-2 px-3 rounded-sm mt-32">
        <button
        v-if="!pdfAndSave"
          class="btn bg-pri bd-pri text-white mt-2 mb-2 fs-14 fw-600 rounded-xl w-full"
          style="width: 80px; height: 36px"
          @click="savePhieuTuVan()"
          :disabled="save_loading"
        >
          <div
            class="p-center d-flex justify-content-center align-items-center"
            v-if="save_loading"
          >
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
            ></span>
          </div>
          <span v-else>Lưu</span>
        </button>
        <button v-else
          class="btn bg-pri bd-pri text-white mt-2 mb-2 fs-14 fw-600 rounded-xl w-full"
          style="width: 80px; height: 36px"
          @click="onSave2()"
          :disabled="save_loading"
        >
          <div
            class="p-center d-flex justify-content-center align-items-center"
            v-if="save_loading"
          >
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
            ></span>
          </div>
          <span v-else>Lưu</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'
import html2pdf from 'html2pdf.js'
import _ from 'lodash'
export default {
  name: 'TemplatePhieuKhamBenh',
  props: [
    'form',
    'update_id',
    'is_create',
    'is_update',
    'user',
    'hr',
    'consultation',
    'pdfAndSave'
  ],
  data () {
    return {
      clinic: null,
      save_loading: false,
      doctor: appUtils.getLocalUser(),
      status: 0,
      centerDialogVisible: false,
      start_time: null,
      CheckSave: 1
    }
  },
  mounted () {
    this.clinic = this.$globalClinic
    this.$bus.on('LuuPhieuKham:save', () => {
      this.savePhieuTuVan()
    })
  },
  destroyed () {
    this.$bus.off('LuuPhieuKham:save')
  },
  watch: {
    consultation (value) {
      this.status = value.status
      if (value.start_time) this.$emit('startTime', value.start_time)
    }
  },
  methods: {
    addConsultation () {
      this.$refs.ModalAdd.openDialog(null, this.consultation)
    },
    exp () {
      this.save_loading = true
      setTimeout(() => {
        this.exportPdf()
      }, 50)
    },
    exportPdf () {
      let self = this
      let options = {
        margin: 14,
        filename: `phieukhamsuckhoe_${window.moment().unix()}.pdf`,
        image: { type: 'jpeg', quality: 2 },
        html2canvas: {
          dpi: 500,
          scale: 4,
          letterRendering: true,
          allowTaint: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf()
        .from(this.$refs.documente)
        .set(options)
        .toPdf()
        .outputPdf()
        .then((pdf) => {
          let newpdf = btoa(pdf)
          let file = this.dataURLtoFile(
            'data:application/octet-stream;base64,' + newpdf,
            `phieutuvan_${window.moment().unix()}.pdf`
          )
          self.xuatPhieuTuVan(file, self.form)
          this.save_loading = false
        })
        .save()
    },
    onSave2 () {
      this.save_loading = true
      setTimeout(() => {
        this.exportPdf2()
      }, 50)
    },
    exportPdf2 () {
      let self = this
      let options = {
        margin: 14,
        filename: `phieukhamsuckhoe_${window.moment().unix()}.pdf`,
        image: { type: 'jpeg', quality: 2 },
        html2canvas: {
          dpi: 500,
          scale: 4,
          letterRendering: true,
          allowTaint: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf()
        .from(this.$refs.documente)
        .set(options)
        .toPdf()
        .outputPdf()
        .then((pdf) => {
          let newpdf = btoa(pdf)
          let file = this.dataURLtoFile(
            'data:application/octet-stream;base64,' + newpdf,
            `phieutuvan_${window.moment().unix()}.pdf`
          )
          self.xuatPhieuTuVan2(file, self.form)
          this.save_loading = false
        })
    },
    getAvatar () {
      let clinic = this.$globalClinic
      if (clinic && clinic.avatar) {
        return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + clinic.avatar
      }
    },
    async xuatPhieuTuVan (file, form) {
      let self = this
      let data = form
      let fileSave
      data.start_time = self.getDateTime2(data.start_time)
      if (file) data.pdf_file = file
      this.$emit('saveFile', fileSave)
    },
    async xuatPhieuTuVan2 (file, form) {
      let self = this
      let data = form
      data.start_time = self.getDateTime2(data.start_time)
      if (file) data.pdf_file = file
      this.savePhieuTuVan(data)
    },
    async savePhieuTuVan (form) {
      let self = this
      form = {
        // health_record_id: this.hr?.id,
        ...this.form,
        consultation_id: this.consultation.id,
        start_time: this.start_time || undefined,
        signed_time: this.form.signed_time || undefined,
        print_time: this.form.print_time || undefined
      }
      form.start_time = self.getDateTime2(form.start_time)
      if (self.update_id) {
        const formData = new FormData()
        for (const key in form) {
          if (!_.isNil(form[key])) formData.set(key, form[key])
        }
        try {
          this.save_loading = true
          await self.$rf
            .getRequest('DoctorRequest')
            .updateConsultation(self.update_id, formData)
            .then((res) => {
              self.$emit('PhieuKhamBenh:refresh')
              self.$bus.emit('ViewKhamBenh:refresh')
              self.$emit('refresh')
              if (self.CheckSave === 1) {
                self.showSaveSuccess()
              }
            })
        } finally {
          this.save_loading = false
        }
      }
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    showSaveSuccess () {
      this.$toast.open({
        message: 'Lưu thành công',
        type: 'success'
      })
    },
    getDate (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getDateTime (date_time) {
      return date_time && window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    getDateTime2 (date_time) {
      return date_time && window.moment(date_time).format('YYYY-MM-DD HH:mm')
    },
    getDateTime3 (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY HH:mm')
    },
    getDateTime4 (date_time) {
      return (
        date_time && window.moment(date_time).format('HH:mm:ss DD/MM/YYYY')
      )
    },
    getHMDMYVN (date_time) {
      let dt = window.moment(date_time)
      // let hm = dt.clone().format('HH:mm')
      let d = dt.clone().format('DD')
      let m = dt.clone().format('MM')
      let y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    async startTime (status = null) {
      if (this.status === 1 && !this.form?.prehistoric && !status) {
        alert('Bạn cần nhập Kết luận.')
        return
      }
      if (status) this.status = 0
      console.log('this.status', this.status)
      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .updateStatusConsultation(this.consultation?.id, this.status ? 2 : 1)
        if (res.data) {
          this.$emit('refresh')
          this.$emit('PhieuKhamBenh:refresh')
          if (!this.status) {
            this.status = 1
            this.start_time = res.data.start_time
            this.$emit('startTime', res.data.start_time)
            this.$message({
              message: 'Bắt đầu khám thành công',
              type: 'success'
            })
          } else {
            this.status = 2
            this.$message({
              message: 'Kết thúc khám thành công',
              type: 'success'
            })
          }
        }
      } catch (error) {
        console.log('startTime', error)
      }
    },
    signing (show) {
      this.centerDialogVisible = show
    },
    async sendConsultationToPatient () {
      const self = this
      if (!self.consultation) return
      if (self.consultation.sms_latest) {
        if (
          !confirm(
            `Kết quả khám đã được trả cho Bệnh nhân vào ${self.getDateTime4(
              self.consultation.sms_latest.created_at
            )}, bạn có chắc muốn gửi lại cho Bệnh nhân?`
          )
        ) { return }
      }
      self.save_loading = true
      await self.$rf
        .getRequest('DoctorRequest')
        .sendConsultationToPatient(this.consultation.id)
        .then((resp) => {
          self.$emit('refresh')
          self.$message({
            message: 'Đã gửi thành công',
            type: 'success'
          })
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          self.save_loading = false
        })
    }
  }
}
</script>
<style lang="css" scoped>
@font-face {
  font-family: Time;
  src: url(../../../../public/assets/font/tnr.ttf);
}

p {
  font-family: Time;
  color: black;
}

.fz-13 {
  font-size: 16px;
  font-family: Time;
}

.fz-11 {
  font-size: 14px;
  font-family: Time;
}

.wordbreak {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.pre-wrap {
  white-space: pre-wrap;
}
.text-red {
  color: #e70000;
}
.fz-10 {
  font-size: 13px;
  font-family: Time;
}
.fz-16 {
  font-size: 19px;
  font-family: Time;
}
.c-info {
  width: 25%;
}
.midle {
  width: 75%;
  text-align: center;
  margin-left: -12.5%;
}
.tnr {
  font-family: Time;
}
</style>
