<template>
  <div class="p-3 txt-grey-900 fs-14 fw-500">
    <div
      class="flex justify-end mb-4"
      @click="close"
    >
      <i class="el-icon-close cursor-pointer"></i>
    </div>
    <div class="bg-white rounded-2xl p-3 h-auto overflow-auto">
      <div class="flex justify-between">
        <div class="w-1/2">
        </div>
        <el-button
          type="primary"
          class="text-sm"
          @click="openCreateIndication"
        >+ {{$t('multidisciplinary_board.btn_add')}}</el-button>
      </div>
      <div class="text-center text-2xl">
        {{$t('schedule_page.lbl_indication_list')}}
      </div>
      <el-table
        row-class-name="text-black text-sm cursor-pointer"
        header-cell-class-name="text-black text-base font-semibold"
        :data="listIndication"
        @row-click="viewDetail"
        class="w-full mt-3"
      >
        <el-table-column
          prop="name"
          label="Tên chỉ định"
        >
        </el-table-column>
        <el-table-column
          label="Tên phiếu chỉ định"
          :formatter="getName"
        >
        </el-table-column>
        <el-table-column
          label="Nơi thực hiện"
          :formatter="getDataDemo"
        >
        </el-table-column>
        <el-table-column
          prop="due_date"
          width="160px"
          label="Ngày đến hạn"
          :formatter="formatDateDMY"
        >
        </el-table-column>
        <el-table-column
          label="Kết luận"
          :formatter="getExaminationResult"
        >
        </el-table-column>
        <!-- <el-table-column label="Trạng thái">
        </el-table-column> -->
      </el-table>

    </div>
    <ModalDetail ref="ModalDetail" />
    <ModalCreateIndicationOrder
      ref="ModalCreateIndicationOrder"
      :hr_id="this.$route.params.id"
      @refresh="getIndicationsByMR"
    />
  </div>
</template>
      
<script>
import dataDemo from '../../../pages/ElectronicMedicalRecords/components/Indications/ModalIndication/dataMemo'
import ModalCreateIndicationOrder from '../../../components/Indications/ModalCreateIndicationOrder.vue'
import ModalDetail from './ModalDetail'
export default {
  components: { ModalDetail, ModalCreateIndicationOrder },
  name: 'IndicationListHR',
  data () {
    return {
      dataState: [],
      dataDemo,
      listIndication: []
    }
  },
  mounted () {
    this.getIndicationsByMR()
  },
  methods: {
    getDataDemo (row) {
      return row.target_id ? this.dataDemo[row.target_id - 1].name : ''
    },
    viewDetail (row) {
      this.$refs.ModalDetail.openDialog(row.id)
    },
    openCreateIndication () {
      this.$refs.ModalCreateIndicationOrder.openDialog('create')
    },
    close () {
      this.$router.push({ path: `/health-records` })
    },
    async getIndicationsByMR () {
      try {
        const params = {
          health_record_id: this.$route.params.id,
          limit: 500,
          sort: 'created_at',
          sort_by: 'desc'
        }
        this.loading = true
        const resp = await this.$rf.getRequest('DoctorRequest').getIndicationsByMR(params)
        this.listIndication = resp.data
        // this.dataState = resp?.data.map(item => {
        //   return { ...item, valueStatus: this.setStatusDefault(item) }
        // })
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    formatDateDMY (row) {
      if (row.due_date) {
        return window.moment(row.due_date).format('DD/MM/YYYY')
      }
    },
    getName (row) {
      return row.indication_order && row.indication_order.name
    },
    getExaminationResult (row) {
      if (row.examination_results?.type === 2) {
        return row.examination_results.conclusion && row.examination_results.conclusion.conclusion
      }
    }
  }
}
    </script>
      
    <style scoped>
</style>