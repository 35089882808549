<template>

  <el-dialog
    title="Phiếu Khám bệnh"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
    append-to-body
  >
    <div class="body-container txt-black">
      <!-- <p class="mb-2 fs-14 fw-400 fst-italic txt-neutral-700 text-right">Ngày giờ: {{ getDateTime(form && form.start_time) }}</p> -->
      <p class="fs-18 fw-700 txt-pri mb-0">{{!user ? form && form.patient_name : user && user.name}}</p>
      <p class="fs-14 txt-neutral-450 fw-400"> <span class="mr-3 fw-500">{{!user ? (form && form.gender === 1 ? 'Nam' : 'Nữ')  || '' : (user && user.gender === 1 ? 'Nam' : 'Nữ')  || ''}}</span> {{!user ? (form && form.birthday ? form && form.birthday : '') : (user && user.birthday ? user && user.birthday : '')}} {{!user ? (form && form.career) || '' : (user && user.career) || ''}}</p>

      <p class="fs-16 fw-400 mb-0 txt-neutral-700">SĐT: {{!user ? form && form.phone : user && user.phone}}</p>
      <p class="fs-16 fw-400 mb-0 txt-neutral-700">Địa chỉ: {{!user ? form && form.address : user && user.address}}</p>
      <p class="fs-16 fw-400 mb-4 txt-neutral-700">
        Người liên hệ: {{(form && form.emergency_contact) || ''}}
        {{form && form.contact_phone ? ', SĐT: ' + form && form.contact_phone : ''}}
      </p>
      <p class="fs-16 fw-500 mb-2">Thời gian</p>
      <div class="col-lg-8 col-md-7 col-xl-9 mb-12">
        <div class="row">
          <div class="col-md-6 d-flex">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              placeholder="Chọn ngày và giờ"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Lý do khám bệnh
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="2"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Tư vấn quá trình điều trị"
          v-model="form.reason"
        >

                    </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">
          Diễn biến
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.progression"
        >

                    </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Kết quả xét nghiệm
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.prehistoric"
        >

                    </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Chẩn đoán
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.diagnose"
        >

                    </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500  fs-16 fw-500 mb-2">
          Chỉ định
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="2"
          class="form-control form-control-bg bg-form  txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.indication"
        >

                    </textarea>
      </div>
      <div>
        <TempPhieuKhamBenh
          v-if="form && !loading"
          :is_create="is_create = true"
          :form="form"
          :user="user"
          :hr="hr"
          :close="handleClose"
          :refresh="refresh"
          @saveFile="saveFileComplete"
        ></TempPhieuKhamBenh>
      </div>
      <div
        class="p-center d-flex justify-content-center align-items-center"
        v-if="loading"
      >
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
        ></span>
      </div>
    </div>
  </el-dialog>

</template>
  <script>
import TempPhieuKhamBenh from '../../templates/PhieuKhamBenh/PhieuKhamBenh.vue'
export default {
  name: 'ModalAddConsultation',
  components: { TempPhieuKhamBenh },
  props: ['hr', 'user', 'appt_id'],
  data () {
    return {
      clinic: null,
      loading: false,
      dialogVisible: false,
      form: {
        appt_id: 0,
        progression: '',
        diagnose: '',
        indication: '',
        reason: '',
        prehistoric: '',
        patient_name: '',
        birthday: '',
        career: '',
        gender: '',
        address: '',
        phone: '',
        emergency_contact: '',
        contact_phone: '',
        start_time: window.moment(),
        city: '',
        notes: '',
        consultation: null
      }
    }
  },
  watch: {
    user: function (user, old) {
      this.form.emergency_contact = user?.emergency_contact
      this.form.contact_phone = user?.contact_phone
    },
    consultation: function (consultation, old) {
      if (consultation) {
        this.form.progression = consultation.progression
        this.form.diagnose = consultation.diagnose
        this.form.indication = consultation.indication
        this.form.prehistoric = consultation.prehistoric
        this.form.reason = consultation.reason
        this.form.notes = consultation.notes
        if (this.consultation.appointment && this.consultation.appointment.doctor) {
          this.form.doctor = consultation.appointment && consultation.appointment.doctor
        }
      }
      console.log(consultation)
    },
    appt_id: function (appt_id, old) {
      if (appt_id && (this.is_create)) {
        this.getAppts(this.appt_id)
      }
    }
  },
  mounted () {
    this.form.patient_name = this.hr?.person?.name
    this.form.person_id = this.hr?.person?.id
    this.form.birthday = this.hr?.person?.birthday ? this.getAges(this.hr?.person?.birthday) : ''
    this.form.career = this.hr?.person?.career
    this.form.gender = this.getGender(this.hr?.person?.gender)
    this.form.address = this.hr?.person?.address
    this.form.phone = this.hr?.person?.phone
    this.form.emergency_contact = this.hr?.person?.emergency_contact
    this.form.contact_phone = this.hr?.person?.contact_phone
    this.form.start_time = window.moment(this.hr?.person?.start_time)
    if (this.consultation) {
      this.form.progression = this.consultation.progression
      this.form.diagnose = this.consultation.diagnose
      this.form.indication = this.consultation.indication
      this.form.prehistoric = this.consultation.prehistoric
      this.form.reason = this.consultation.reason
      this.form.notes = this.consultation.notes
      this.form.start_time = window.moment(this.consultation.start_time)
      if (this.consultation.appointment && this.consultation.appointment.doctor) {
        this.form.doctor = this.consultation.appointment && this.consultation.appointment.doctor
      }
    }
    if (this.appt_id) {
      this.form.appt_id = this.appt_id
      this.getAppts(this.appt_id)
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    },
    openDialogConsultation () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    async getAppts (id) {
      let self = this
      console.log('getAppts')
      await self.$rf.getRequest('DoctorRequest').getAppts(id).then((res) => {
        console.log(res)
        if (res.data) {
          self.form.doctor = res.data.doctor
        }
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
      })
    },
    showModalPrint (show) {
      window.$('#modal__print').modal(show ? 'show' : 'hide')
    },
    getGender (gender = 1) {
      return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : gender === 3 ? 'Khác' : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return bd.format('DD/MM/YYYY') + ' (' + age + ' tuổi)'
    },
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    getTime (date) {
      return window.moment(date).format('HH:mm')
    },
    getDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    saveFileComplete (data) {
      this.$emit('saveFileComplete', data)
    }
  }
}
  </script>
  <style lang="css" scoped>
</style>
  