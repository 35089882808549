<template>
  <div class="p-3 txt-grey-900 fs-14 fw-500">
    <div
      class="flex justify-end mb-4"
      @click="close"
    >
      <i class="el-icon-close cursor-pointer"></i>
    </div>
    <div class="bg-white rounded-2xl p-3 h-auto overflow-auto">
      <div class="flex justify-between">
        <div class="w-1/2">
        </div>
        <el-button
          type="primary"
          class="text-sm"
          @click="addConsultation"
        >+ Thêm Phiếu</el-button>
      </div>
      <el-table
        @row-click="viewDetail"
        class="w-full mt-3"
        :data="list.data"
        row-class-name="cursor-pointer"
      >
        <el-table-column
          prop="code"
          label="Tên phiếu"
        >
        </el-table-column>
        <el-table-column label="Ngày">
          <template slot-scope="scope">
            <span class="">{{formatDateDMY(scope.row.start_time)}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Trạng thái">
          <template slot-scope="scope">
            <span class="">{{scope.row.person && scope.row.person.phone}}</span>
          </template>
        </el-table-column> -->

      </el-table>
      <Pagination
        v-show="list && list.total"
        :items="list"
        :current_page="current_page"
        @onRefresh="getConsultation"
      ></Pagination>
    </div>
    <ModalAddConsultation
      ref="ModalAddConsultation"
      @refresh="getConsultation"
    />
    <ModalViewConsultation ref="ModalViewConsultation" />
  </div>
</template>
    
  <script>
import ModalAddConsultation from './PhieuKhamBenh/ModalAdd.vue'
import ModalViewConsultation from './PhieuKhamBenh/ViewPhieuKham.vue'
// import PhieuKhamBenh from '../PhieuKhamBenh/Detail'
import moment from 'moment'
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination, ModalAddConsultation, ModalViewConsultation },
  name: 'PhieuKhamBenhDetail',
  data () {
    return {
      hr: null,
      current_page: 1,
      list: [],
      params: {
        page: 1
      }
    }
  },
  mounted () {
    this.getConsultation()
    this.getHealthRecordByID()
    this.$bus.on('PhieuKhamBenh:refresh', () => {
      this.getConsultation()
    })
  },
  destroyed () {
    this.$bus.off('PhieuKhamBenh:refresh')
  },
  methods: {
    viewDetail (event) {
      this.$refs.ModalViewConsultation.openDialogView(event)
    },
    close () {
      this.$router.push({ path: `/health-records` })
    },
    async getHealthRecordByID () {
      try {
        await this.$rf.getRequest('DoctorRequest').getHealthRecordByID(this.$route.params.id).then(res => {
          this.hr = res.data
          const personHR = this.hr.person
          this.$store.commit('setPersonHR', { personHR })
        })
      } catch (e) {
        console.log(e)
      }
    },
    addConsultation () {
      this.$refs.ModalAddConsultation.openDialog('createConsultation')
    },
    async getConsultation () {
      try {
        this.current_page = parseInt(this.$route.query.page) || 1
        const param = {
          ...this.params,
          sort: 'updated_at',
          sort_by: 'desc',
          limit: 10,
          page: this.current_page,
          health_record_id: this.$route.params.id
        }
        await this.$rf.getRequest('DoctorRequest').getConsultation(param).then(res => {
          this.list = res.data
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return date && moment(date).format('HH:mm:ss DD/MM/YYYY')
    }
    // viewDetail (obj) {
    //   this.$refs.ModalViewHR.openDialog(obj.id)
    // }
  }
}
  </script>
    
  <style scoped>
</style>