<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    append-to-body
    width="600px"
  >
    <div class="container">
      <div class="text-center text-2xl font-semibold mb-3">{{isCreate ? $t('health_book_page.lbl_add_book_title') : $t('health_book_page.lbl_edit_book_title')}}</div>

      <el-form
        v-if="form"
        :model="form"
        ref="formRef"
      >
        <div class="">
          <div class="row">
            <el-form-item
              class="col-6"
              :label= "$t('health_book_page.lbl_patient')"
              prop="person_id"
            >
              <div v-if="!stat || stat === 'edit'">
                <el-select
                  v-model="personName"
                  class="w-full"
                  :placeholder= "$t('health_book_page.lbl_select_patient')"
                  filterable
                  :disabled="stat === 'edit'"
                  remote
                  :remote-method="getPersonsV2"
                  @change="setPersonID"
                  :loading="loading"
                  @focus="getPersonsV2()"
                >
                  <el-option
                    v-for="item in persons"
                    :key="item.id + 'hr'"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div v-if="stat === 'create'">
                <el-select
                  v-model="personName"
                  disabled
                  @change="setPersonID"
                  class="w-full"
                  @focus="getPersonsV2()"
                >
                  <el-option
                    v-for="item in persons"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </el-form-item>
            <div
              v-if="!stat"
              class="col-6 flex items-center justify-center"
            >
              <el-button
                class="text-white text-sm"
                type="primary"
                @click="addPerson"
                v-if="!person"
              >{{$t('health_book_page.lbl_add_patient')}}</el-button>
            </div>
          </div>
          <el-form-item
            class="col-md-6 pr-2"
            :label="$t('health_book_page.lbl_exam_date')"
          >
            <el-date-picker
              type="datetime"
              :placeholder="$t('health_book_page.lbl_select_datetime')"
              v-model="form.visited_at"
            ></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('health_book_page.lbl_exam_reason')">
            <el-input
              v-model="form.visit_reason"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="flex justify-end">
        <el-button
          class="border-0 text-base"
          @click="handleClose"
        >{{$t('health_book_page.lbl_cancel')}}</el-button>
        <el-button
          class="text-white w-20 text-sm"
          type="primary"
          @click="handleSubmit"
        >{{$t('health_book_page.lbl_save')}}</el-button>
      </div>
    </div>
    <ModalAddPerson
      ref="ModalAddPerson"
      @refresh="setPerson"
    ></ModalAddPerson>
  </el-dialog>
</template>
  
<script>
import _ from 'lodash'
import ModalAddPerson from '../../components/Person/ModalAddPerson'

export default {
  name: 'ModalAddHR',
  props: ['person'],
  components: { ModalAddPerson },
  data () {
    return {
      dialogVisible: false,
      form: {
        person_id: 0
      },
      stat: '',
      isCreate: true,
      persons: null,
      personName: null,
      loading: false,
      personHr: null
    }
  },
  computed: {
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$nextTick(() => this.cancel())
    },
    openDialog (obj, stat) {
      this.stat = stat
      this.dialogVisible = true
      if (this.person) this.setPerson(this.person)
      this.$nextTick(() => {
        if (obj) {
          this.form = _.cloneDeep(obj)
          this.personName = obj.person?.name
          this.isCreate = false
        }
        if (stat === 'create') {
          this.isCreate = true
          this.personHr = obj
          if (this.personHr) this.setPersonHr(this.personHr)
        }
      })
    },
    cancel () {
      this.form = {}
      this.$refs.formRef.resetFields()
      this.personName = null
    },
    handleSubmit () {
      if (this.isCreate) this.createHealthRecords()
      else this.updateHealthRecords()
    },
    async createHealthRecords () {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return
        try {
          const param = {
            ...this.form,
            clinic_id: this.$globalClinic?.id
          }
          await this.$rf.getRequest('DoctorRequest').createHealthRecords(param).then(res => {
            if (res?.data) {
              this.$message({
                message: 'Thêm mới thành công',
                type: 'success'
              })
              this.handleClose()
              this.$emit('refresh', res?.data)
            }
          })
        } catch (e) {
          this.$message({
            message: 'Thêm mới thất bại!',
            type: 'warning'
          })
          console.log(e)
        }
      })
    },
    async updateHealthRecords () {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return
        try {
          const param = {
            ...this.form
          }
          await this.$rf.getRequest('DoctorRequest').updateHealthRecords(this.form.id, param).then(res => {
            if (res?.data) {
              this.$message({
                message: 'Cập nhật thành công',
                type: 'success'
              })
              this.handleClose()
              this.$emit('refresh', this.form.id)
            }
          })
        } catch (e) {
          this.$message({
            message: 'Cập nhật thất bại!',
            type: 'warning'
          })
          console.log(e)
        }
      })
    },
    async getPersonsV2 (search = null) {
      const params = {
        limit: 50,
        clinic_id: this.$globalClinic?.id
      }
      if (search) {
        params.search = search
      }
      this.loading = true
      let r = await this.$rf.getRequest('DoctorRequest').getPersonsV2(params).then((r) => {
        this.persons = r.data?.data
        return r
      }).catch((e) => {
        console.log(e)
        return false
      }).finally(() => {
        this.loading = false
      })
      return r
    },
    setPerson (person) {
      this.personName = person?.name
      this.setPersonID(person?.id)
    },
    setPersonHr (person) {
      this.personName = person?.name
      this.setPersonID(person?.id)
    },
    setPersonID (value) {
      if (!this.personHr) {
        this.form.person_id = value
      }
      if (this.personHr) {
        this.form.person_id = this.personHr?.id
      }
    },
    addPerson () {
      this.$refs.ModalAddPerson.openDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    line-height: 26px;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-dialog {
    border-radius: 10px;
  }
}
</style>