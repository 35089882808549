<template>
  <el-dialog
    v-if="hr"
    fullscreen
    :modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <div class="relative">
      <div class="text-black font-normal">
        <div>
          <div class="text-2xl text-black font-bold mb-2">
            {{ hr.person && hr.person.name }}
          </div>
          <div class="row space-x-2">
            <div class="col-md-2">
              {{ getGenderAndBirthday() }}
              <span v-if="hr.person && hr.person.age">({{ hr.person && hr.person.age }} {{$t('health_book_page.lbl_age')}})</span>
            </div>
            <div class="col-md-3">
              <span v-if="hr.person && hr.person.address">{{
                hr.person.address
              }}</span>
            </div>
            <div class="col-md-2">{{ hr.person && hr.person.phone }}</div>
            <div class="col-md-2">
              {{$t('health_book_page.lbl_insurance_no')}} {{ hr.person && hr.person.insurance_number }}
            </div>
            <div class="col-md-2">
              {{$t('health_book_page.lbl_profession')}} {{ hr.person && hr.person.career }}
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="row">
          <div class="col-md-8">
            <div class="flex">
              <div class="flex mr-3">
                <div class="font-bold w-32">{{$t('health_book_page.lbl_book_code')}}</div>
                {{ hr.code }}
              </div>

              <a
                class="txt-pri fs-14 fw-500 d-flex align-items-center"
                href="javascript:;"
                @click="editPrescription(hr.id)"
                :disabled="loading"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1"
                >
                  <path
                    d="M5.87354 18.9882H10.1135C10.2451 18.989 10.3756 18.9638 10.4974 18.914C10.6193 18.8642 10.7301 18.7909 10.8235 18.6982L17.7435 11.7682L20.5835 8.98822C20.6773 8.89526 20.7517 8.78466 20.8024 8.6628C20.8532 8.54094 20.8793 8.41023 20.8793 8.27822C20.8793 8.14621 20.8532 8.0155 20.8024 7.89364C20.7517 7.77178 20.6773 7.66118 20.5835 7.56822L16.3435 3.27822C16.2506 3.18449 16.14 3.1101 16.0181 3.05933C15.8963 3.00856 15.7655 2.98242 15.6335 2.98242C15.5015 2.98242 15.3708 3.00856 15.249 3.05933C15.1271 3.1101 15.0165 3.18449 14.9235 3.27822L12.1035 6.10822L5.16354 13.0382C5.07085 13.1317 4.99753 13.2425 4.94776 13.3643C4.898 13.4862 4.87277 13.6166 4.87354 13.7482V17.9882C4.87354 18.2534 4.97889 18.5078 5.16643 18.6953C5.35397 18.8829 5.60832 18.9882 5.87354 18.9882ZM15.6335 5.39822L18.4635 8.22822L17.0435 9.64822L14.2135 6.81822L15.6335 5.39822ZM6.87354 14.1582L12.8035 8.22822L15.6335 11.0582L9.70354 16.9882H6.87354V14.1582ZM21.8735 20.9882H3.87354C3.60832 20.9882 3.35396 21.0936 3.16643 21.2811C2.97889 21.4686 2.87354 21.723 2.87354 21.9882C2.87354 22.2534 2.97889 22.5078 3.16643 22.6953C3.35396 22.8829 3.60832 22.9882 3.87354 22.9882H21.8735C22.1388 22.9882 22.3931 22.8829 22.5806 22.6953C22.7682 22.5078 22.8735 22.2534 22.8735 21.9882C22.8735 21.723 22.7682 21.4686 22.5806 21.2811C22.3931 21.0936 22.1388 20.9882 21.8735 20.9882Z"
                    fill="#20409B"
                  />
                </svg>

                <span>{{$t('health_book_page.btn_edit')}}</span>
              </a>
            </div>
            <div class="flex mt-2">
              <div class="font-bold w-32">{{$t('health_book_page.lbl_exam_date')}}</div>
              {{ formatDateDMY(hr.visited_at) }}
            </div>
            <div class="flex mt-2">
              <div class="font-bold w-32">{{$t('health_book_page.lbl_exam_reason')}}</div>
              {{ !consultation.total ? visit_reason : hr.latest_consultation && hr.latest_consultation.reason }}
            </div>
            <div class="flex mt-2">
              <div class="font-bold w-32">{{$t('health_book_page.lbl_conclude')}}</div>
              {{ hr.latest_consultation && hr.latest_consultation.diagnose }}
            </div>

            <div class="mt-3 row">
              <div
                @click="consultationDetail(hr.id)"
                v-if="consultation.total"
                class="
                  col-md-3
                  border
                  bg-gray-100
                  hover:bg-gray-200
                  border-gray-300
                  rounded-2xl
                  py-6
                  mr-3
                  cursor-pointer
                "
              >
                <div class="flex flex-col justify-center items-center">
                  <div class="fs-15 fw-600 mb-2.5">{{$t('health_book_page.lbl_examination')}}</div>
                </div>
                <div class="flex justify-center">
                  <div class="
                      w-14
                      h-14
                      bg-btn-open-other
                      flex
                      justify-center
                      items-center
                      radius-50
                    ">
                    <p class="mb-0 fs-20 fw-500 txt-pri">
                      {{ consultation.total }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="!consultation.total"
                @click="addConsultation"
                class="
                  col-md-3
                  border
                  bg-gray-100
                  hover:bg-gray-200
                  border-gray-300
                  rounded-2xl
                  py-6
                  mr-3
                  cursor-pointer
                "
              >
                <div class="flex flex-col justify-center items-center">
                  <div class="fs-15 fw-600 mb-2.5">{{$t('health_book_page.lbl_examination')}}</div>
                  <svg
                    class="w-14 h-14"
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.1"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                      fill="#20409B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                      fill="#20409B"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  col-md-3
                  border
                  bg-gray-100
                  hover:bg-gray-200
                  border-gray-300
                  rounded-2xl
                  py-6
                  mr-3
                  cursor-pointer
                "
                @click="openCreateIndication"
              >
                <div class="flex flex-col justify-center items-center">
                  <div class="fs-15 fw-600 mb-2.5">{{$t('health_book_page.lbl_clinical')}}</div>
                  <svg
                    v-if="!hr.indication_count"
                    class="w-14 h-14"
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.1"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                      fill="#20409B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                      fill="#20409B"
                    />
                  </svg>
                  <div
                    class="
                      w-14
                      h-14
                      bg-btn-open-other
                      d-flex
                      justify-content-center
                      align-items-center
                      radius-50
                    "
                    @click="gotoIndications(hr.id)"
                    v-else
                  >
                    <p class="mb-0 fs-20 fw-500 txt-pri">
                      {{ hr.indication_count }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="
                  col-md-3
                  border
                  bg-gray-100
                  hover:bg-gray-200
                  border-gray-300
                  rounded-2xl
                  py-6
                  mr-3
                  cursor-pointer
                "
                @click="goToMed"
              >
                <div class="flex flex-col justify-center items-center">
                  <div class="fs-15 fw-600 mb-2.5">{{$t('health_book_page.lbl_prescription')}}</div>
                  <svg
                    class="w-14 h-14"
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="!hr.prescriptions_count"
                  >
                    <path
                      opacity="0.1"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                      fill="#20409B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                      fill="#20409B"
                    />
                  </svg>
                  <div
                    class="
                      w-14
                      h-14
                      bg-btn-open-other
                      d-flex
                      justify-content-center
                      align-items-center
                      radius-50
                    "
                    v-else
                  >
                    <p class="mb-0 fs-20 fw-500 txt-pri">
                      {{ hr.prescriptions_count }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="overflow-auto max-h-480"
              v-if="histories && histories.length"
            >
              <el-timeline>
                <el-timeline-item
                  v-for="(history, index) in histories"
                  :key="'hr_' + index"
                  :timestamp="formatDate(history.date)"
                  color="#20419B"
                >
                  <a
                    @click="goToDetail(history)"
                    class="cursor-pointer text-black"
                  >{{ history.title }}</a>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="flex justify-center items-center my-2">
              <el-button
                class="text-white text-sm"
                type="primary"
                @click="createAppt(hr.id)"
              >
                <div>{{$t('health_book_page.lbl_revisit')}}</div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <ModalAddHR
        ref="ModalAddHR"
        @refresh="getHealthRecordByID"
      />
      <ModalAddConsultation
        ref="ModalAddConsultation"
        @refresh="getConsultation(hr.id)"
      />
      <PhieuKhamBenh
        :user="hr && hr.person"
        ref="PhieuKhamBenhDetail"
        @refresh="getConsultation"
      />
      <ModalCreateIndicationOrder
        ref="ModalCreateIndicationOrder"
        :hr_id="hr.id"
        @refresh="refreshHR"
      />
    </div>
  </el-dialog>
</template>
  
  
<script>
import moment from 'moment'
import ModalAddHR from './ModalAdd'
import appUtils from '../../utils/appUtils'
import ModalAddConsultation from './PhieuKhamBenh/ModalAdd.vue'
import PhieuKhamBenh from '../PhieuKhamBenh/Detail'
import ModalCreateIndicationOrder from '../../components/Indications/ModalCreateIndicationOrder.vue'
export default {
  name: 'ModalViewHR',
  components: { ModalAddHR, ModalAddConsultation, PhieuKhamBenh, ModalCreateIndicationOrder },
  data () {
    return {
      dialogVisible: false,
      hr: null,
      loading: false,
      visit_reason: '',
      histories: [],
      hrId: null,
      consultation: {},
      objId: null
    }
  },
  watch: {
    dialogVisible (stt) {
      if (!stt) {
        let query = Object.assign({}, this.$route.query)
        if (query.id) {
          delete query.id
          this.$router.push({ query }).catch(() => { })
        }
      } else {
        let query = Object.assign({}, this.$route.query)
        if (this.hrId) {
          query.id = this.hrId
          this.$router.push({ query }).catch(() => { })
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    refreshHR () {
      this.getHealthRecordByID(this.objId)
    },
    goToDetail (item) {
      if (item.appt_id) {
        this.$router.push({ path: `/doctor/appts/${item.appt_id}`, query: { name: 'skb' } })
      } if (item.id) {
        this.$refs.PhieuKhamBenhDetail.openDialog(item)
      }
    },
    addConsultation () {
      this.$refs.ModalAddConsultation.openDialog('createConsultation')
    },
    consultationDetail (id) {
      return this.$router.push({ path: `/doctor/consultations-detail/${id}` })
    },
    gotoIndications (id) {
      return this.$router.push({ path: `/health-records/indications/${id}` })
    },
    async getConsultation (id) {
      const params = {
        health_record_id: id,
        litmit: 1
      }
      try {
        await this.$rf.getRequest('DoctorRequest').getConsultation(params).then(res => {
          this.consultation = res?.data
        })
      } catch (e) {
        console.log(e)
      }
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (obj) {
      this.objId = obj.id
      this.getConsultation(obj.id)
      this.visit_reason = obj?.visit_reason
      this.hrId = obj.id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.getHealthRecordByID(obj.id)
      })
    },
    cancel () {
      this.hr = null
    },
    formatDate (date) {
      return date && moment(date).format('HH:mm - DD/MM/YYYY')
    },
    editPrescription () {
      this.$refs.ModalAddHR.openDialog(this.hr, 'edit')
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    async getHealthRecordByID (id) {
      try {
        if (id) {
          await this.$rf.getRequest('DoctorRequest').getHealthRecordByID(id).then(res => {
            this.hr = res?.data
            const personHR = this.hr.person
            this.$store.commit('setPersonHR', { personHR })
            this.histories = res.data?.histories
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getGenderAndBirthday () {
      let result = ''
      if (this.hr?.person?.gender) result = appUtils.getGender(this.hr.person?.gender)
      if (this.hr?.person?.birthday) {
        if (this.hr?.person?.gender) result = result + ', ' + this.hr?.person?.birthday
        else result = this.hr?.person?.birthday
      }
      return result
    },
    createAppt () {
      this.$router.push({ name: 'CreateAppointment', query: { hr_id: this.hrId } })
    },
    openCreateIndication () {
      if (!this.hr.indication_count) {
        this.$refs.ModalCreateIndicationOrder.openDialog('create')
      }
    },
    goToMed () {
      this.$router.push({ name: 'HealthRecordMed', params: { id: this.hrId } })
    }
  }
}
</script>
  
<style lang="scss" scoped>
::v-deep {
  .el-timeline-item__tail {
    border-left: 2px dashed #20419b;
  }
}
.bg-btn-open-other {
  background-color: rgba(71, 109, 214, 0.1);
}
.max-h-480 {
  max-height: 480px;
}
</style>